<template>
    <div class="bottomCss">
      
       <div class="footerCont-main">
        <div class="footerCont">
        
        <div class="right">
          <div v-for="item in itemCont" :key="item.name">
            <div class="itemTitle">{{ item.name }}</div>
            <div v-for="itemList in item.cont" class="itemList" :key="itemList">
              {{ itemList }}
            </div>
          </div>
        </div>

        <div class="left" style="text-align: end;">
          <img class="itemTitle" src="../assets/imgs/zllogo.png" alt="">
          <div class="itemName">
            <div style="display: flex;flex-direction: column;align-items: center;">
              <img style="width: 80px;" src="../assets/imgs/app.png" alt="">
              <p>易学酷APP下载</p>
            </div>
            <div style="display: flex;flex-direction: column;align-items: center;margin-left: 30px;">
              <img style="width: 80px;" src="../assets/imgs/wx.png" alt="">
              <p>微信公众号</p>
            </div>
          </div>
          <div class="itemName">
            <span style="display: flex;align-items: center;">
              <img style="width: 20px;" src="../assets/imgs/phon.png" alt="">
              028-68850057
            </span>
            <span style="display: flex;align-items: center;margin-left: 40px;">
              <img style="width: 20px;" src="../assets/imgs/weizhi.png" alt="">
              四川省成都市武侯区英祥财富领域2栋14楼
            </span>
          </div>
          
        </div>
      </div>
      <!-- <p style="margin: 0 auto;width: 1200px;text-align: center;padding: 20px;">Copyright © 2021 四川赋能智盈企业管理有限公司 蜀ICP备20023024号</p> -->
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=蜀ICP备20023024号">
        <img src="" style="float:left;"/>
        <p style="font-size: 14px;margin: 0 auto;width: 1200px;text-align: center;padding: 20px;color: aliceblue;">Copyright © 2021 蜀ICP备20023024号</p>
      </a>
       </div>
    </div>
  </template>
  <script>
  export default {
    name: "pageFooter",
    data() {
      return {
        itemCont: [
          {
            name: "产品",
            cont: ["快码", "智图", "微剪", "元宇宙直播","结算中心"],
          },
          {
            name: "解决方案",
            cont: ["会议直播", "企业直播", "活动直播", "年会直播", "直播营销"],
          },
          { name: "行业方案", cont: ["医疗行业", "零售快销", "IT科技"] },
          { name: "资源中心", cont: ["帮助中心",] },
          { name: "关于我们", cont: ["关于中疗", "联系我们"] },
        ],
      };
    },
    props: ["routeName"],
    // watch: {
    //   '$route.path'(oldVal,newVal){
    //     console.log('oldVal:',oldVal)
    //     console.log('newVal:',newVal)
    //     console.log('this.$route:',this.$route)
    //   }
    // },
    watch: {
      //监听
      // routeName(newVal, oldVal) {
      //   console.log("routeName：newVal", newVal);
      //   console.log("routeName：oldVal", oldVal);
      // },
    },
    mounted() {
      // this.$nextTick(() => {
      //   console.log("routeName:", this.routeName);
      // });
    },
    methods: {
      toNext(item) {
        console.log("跳转页面的值：item:", item);
        this.$router.push({ name: item.val });
      },
    },
  };
  </script>
  <style lang="less" scoped>
  .bottomCss {
    width: 100%;
    // background: #232323;
    color: #cccccc;
    font-size: 14px;
    text-align: left;
    .footerCont-main{
        width: 100%; 
        background: #162737;
     .footerCont {
      width: 1200px;
      margin: 0 auto;
      // height: 276px;
      padding: 30px 0;
      display: flex;
      // align-items: center;
      justify-content: space-between;
  
      .left {
        .itemTitle {
          width: 120px;
          
        }
        .itemName{
          display:flex;
          margin: 30px 0;
          justify-content: end;
        }
        .itemImg {
          margin-top: 18px;
          > img {
            margin-right: 20px;
          }

        }
      }
      .right {
        display: flex;
        justify-content: space-between;
        width: 540px;
        font-size: 16px;
        .itemTitle {
          font-weight: bold;
          margin-bottom: 20px;
        }
        .itemList {
          margin-bottom: 10px;
          cursor: pointer;
          font-size: 12px;
        }
      }
    }
    }
    
  }

  </style>