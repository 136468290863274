import request from '@/putils/request.js';

//添加-编辑投票信息
export function voteEdit(params) {
    return request('/api/live/vote/edit', {
      method: 'post',
      data: params,
    });
  }

//查询投票信息
export function getVoteList(params) {
  return request('/api/live/vote/list', {
    method: 'get',
    params,
  });
}

//编辑投票状态(发布-结束发布-再次发布)
export function editStatus(data) {
  return request('/api/live/vote/edit/status', {
    method: 'POST',
    data
  });
}

//删除投票信息
export function deleteVote(params) {
  return request('/api/live/vote/delete/'+params.id, {
    method: 'POST',
    data: params,
  });
}

//提交投票信息
export function voteCommit(params) {
  return request('/api/live/vote/commit', {
    method: 'POST',
    data: params,
  });
}

//根据房间号停止所有投票信息
export function stopRoom(params) {
  return request('/api/live/vote/stop/room', {
    method: 'get',
    params,
  });
}

//下载投票
export function exportVote(params) {
  return request('/api/live/vote/export', {
    method: 'POST',
    data: params,
    responseType: 'blob'
  });
}

//可用的直播现场列表数据
export function getUsefulList(params) {
  return request('/api/live/room/scene/useful/list', {
    method: 'get',
    params,
  });
}
