<template>
  <div class="hello">
    <div class="topCss" id="topCsss">
      <div class="headerCont">
        <div class="left">
          <img src="../assets/imgs/logo.png" alt="" style="width: 82px; cursor: pointer"
            />
          <!-- <div class="listItem">
            <div v-for="item in dataList" :key="item.val" @click="toNext(item)"
              :class="isTrue(item.val, topName) ? 'isActivation' : ''">
              {{ item.name }}
            </div>
          </div> -->


          <el-menu  class="el-menu-demo" mode="horizontal"
          router >
            <el-menu-item index="/">首页</el-menu-item>
            <el-submenu index="2">
              <template slot="title">产品与服务</template>
              <el-menu-item index="medical">医疗直播</el-menu-item>
              <el-menu-item index="Metaverse">元宇宙直播</el-menu-item>
            </el-submenu>
            <el-menu-item index="chCooper" >渠道合作</el-menu-item>
            <el-menu-item index="aboutus">关于我们</el-menu-item>
            <el-menu-item index="Logins" >登录/注册</el-menu-item>
          </el-menu>
        </div>
      </div>
      <!-- <div class="headerCont-main">
        <div>
          <h3>活动策划+直播服务+全权指导</h3>
          <p class="banner-item"></p>
          <h1>一站式直播解决方案</h1>
        </div>
        <button>了解更多</button>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      dataList: [
        { name: "首页", val: "index" },
        { name: "产品与服务", val: "commercialCopyright" },
        { name: "渠道合作", val: "chCooper" },
        { name: "关于我们", val: "aboutus" },
        { name: "登录/注册", val: "Logins" },

      ],
      topName: "",
      activeIndex2:'/'
    }
  },
  created() {
    this.topName = this.$route.name;
  },
  methods: {
    toNext(item) {

      this.$router.push({ name: item.val });
    },
    isTrue(val, topName) {

      if (topName.indexOf("video") != -1) {
        topName = "videoCopyright";
      }
      if (topName.indexOf("starImage") != -1) {
        topName = "starImage";
      }
      if (topName.indexOf("original") != -1) {
        topName = "originalCopyright";
      }
      if (topName.indexOf("musical") != -1) {
        topName = "musicalCopyright";
      }
      if (topName.indexOf("game") != -1) {
        topName = "gameCopyright";
      }
      if (topName.indexOf("creator") != -1) {
        topName = "creator";
      }
      if (topName.indexOf("commercial") != -1) {
        topName = "commercialCopyright";
      }
      if (val == topName) {
        return true;
      } else {
        // let str = "";
        return false;
      }
    },
  }
}
</script>


<style scoped lang="less">
ul{
  background: none;
}
/deep/.el-menu-item{
  margin-left: 20px;
}
/deep/.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover{
  background: none;
}
/deep/.el-menu--horizontal>.el-submenu .el-submenu__title:hover{
   background: none;
}
/deep/.el-menu.el-menu--horizontal{
  border-bottom: none;
}
/deep/.el-menu--horizontal>.el-menu-item{
  color: #fff;
  font-size: 14px;
}
/deep/.el-menu--horizontal>.el-menu-item:hover{
  color: #a09f9f;
}
/deep/.el-menu--horizontal>.el-submenu .el-submenu__title{
  color: #fff;
  font-size: 14px;
}
/deep/.el-menu--horizontal>.el-submenu .el-submenu__title:hover{
  color: #a09f9f;
}
.topCss {
  width: 100%;
  // background: url(../assets/img/banner.png) no-repeat;
  // background-size: 100% 100%;
  // z-index: 1;
  // height: 400px;
  position: relative;

  .headerCont {
    width: 1200px;
    margin: 0 auto;
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 10;

    .left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .listItem {
      display: inline-flex;
      width: 650px;
      vertical-align: top;
      line-height: 37px;
      justify-content: space-around;
      align-content: center;

      >div {
        font-size: 16px;
        // font-family: PingFangSC-Regular, PingFang SC;
        //
        color: #bfbfbf;
        cursor: pointer;
      }

      >.isActivation {
        color: #ffffff;
        font-weight: bold;
        position: relative;
      }

      >.isActivation::after {
        content: "";
        display: inline-block;
        width: 38px;
        height: 13px;
        position: absolute;
        background: url(https://test.jyaqbz.net:18188/resources/images/jiwuzhe/isActivationImg.png);
        left: 50%;
        bottom: -3px;
        transform: translateX(-50%);
      }

      >div:hover {
        color: #ffffff;
        font-weight: bold;
        position: relative;
      }

      >div:hover::after {
        content: "";
        display: inline-block;
        width: 38px;
        height: 13px;
        position: absolute;
        background: url(https://test.jyaqbz.net:18188/resources/images/jiwuzhe/isActivationImg.png);
        left: 50%;
        bottom: -3px;
        transform: translateX(-50%);
      }
    }


  }
}

.headerCont-main {
  width: 1200px;
  margin: 80px auto;

  h3 {
    color: #fff;
    font-size: 22px;
  }

  h1 {
    color: #FFAB00;
  }

  .banner-item {
    width: 92px;
    height: 3px;
    background: #FFFFFF;
    margin: 20px 0;
  }

  button {
    margin-top: 50px;
    padding: 5px 18px;
    border-radius: 20px;
    background-color: #FFAB00;
  }
}
</style>
