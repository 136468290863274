<template>
    <div class="body" v-show="loginIsShow">
        <div class="myContainer">
            <!-- logo -->
            <div class="logo-left">

            </div>
            <div class="from-right">
                <div class="logoTitle">
                  <div><img src="../assets/img/logo.png" alt=""/></div>
                  <div class="login-tips">
                      欢迎进入
                  </div>
                </div>
                <!-- 表单页面 -->
                <div class="input-from">
                    <div class="inner-from">

                        <el-form ref="form" :model="form">
                            <div class="from-icon">
                                <el-form-item>
                                    <el-input v-model="form.roomId" placeholder="房间号"></el-input>
                                </el-form-item>
                            </div>
                          <div class="from-icon">
                            <el-form-item>
                              <el-input v-model="form.username" placeholder="用户id"></el-input>
                            </el-form-item>
                          </div>
                            <div class="from-icon">
                                <el-form-item>
                                    <el-input v-model="form.ak" placeholder="房间ak" show-password></el-input>
                                </el-form-item>
                            </div>
                        </el-form>
                        <!-- 登录按钮-->
                        <div class="login-btn">
                            <el-button @click="isLogin" type="primary" >
                                进入直播间
                            </el-button>
                        </div>
                    </div>


                </div>

                <!--  底部标识 -->
                <!-- <div class="el-login-footer">
                    <span>Copyright ? 2018-2021 ruoyi.vip All Rights Reserved.</span>
                </div> -->
            </div>


        </div>
    </div>
</template>

<script>
import $Api from "@/api/index";
import liveConfigInfo from '@/config/liveConfigInfo';

export default {
    name: "login",
    data() {
        return {
            form: {
                username: '',
                roomId: '',
                ak: ''
            },
            checked: true,
            loginIsShow: true,

        }
    },
    mixins: [liveConfigInfo],
    methods: {
        //登录
        async isLogin() {
        },
    },
    created() {
      this.getUserInfoFunc()
      const prevurl=window.location.href
      console.log(11111111,prevurl);
      localStorage.setItem('url',prevurl)
    }
}
</script>

<style  lang="less" scoped>
.body {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url(../assets/img/login-bg_01.jpg); */
    background-repeat: no-repeat;
    background-size: 100% 100%;

}

.myContainer {
    width: 960px;
    height: 520px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 3px 5px rgba(175, 192, 206, 0.6);
}

.logo-left {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../assets/img/login-rightBg_03.gif);
    background-size: 100% 100%;

}

.from-right {
    width: 100%;
    height: 100%;
}

.logoTitle {
    width: 100%;
    height: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}

.input-from {
    width: 100%;
    height: 35%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.login-btn{
  width: 100%;
  button{
    width: 100%;
  }
}

.inner-from {
    width: 360px;

}

.login-tips {
    height: 80px;
    color: #0295C4;
    font-size: 30px;
    line-height: 80px;
    margin-left: 30px;
}

.from-icon {
    position: relative;
    margin-bottom: 10px;
}
.el-form-item{
  margin-bottom: 10px !important;
}
.input-icon {
    width: 28px;
    height: 28px;
    position: absolute;
    left: 30px;
    top: 5px;
}

.bottom-msg {
    width: 280px;
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    margin-left: 145px;
}

.el-login-footer {
    height: 40px;
    line-height: 40px;
    /* position: absolute; */
    margin-top: 230px;
    /*
        bottom: 50; */
    /* width: 100%; */
    text-align: center;
    /* color: #fff; */
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
    margin-left: 45px;
}
</style>
