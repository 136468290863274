<template lang="pug">
div.player-message-container(:class="$isMobile ? 'mobile-container' : ''")
  div.message-tab(v-if="!isMobileHorizontalLayout")
    //- div(
    //-   :class="isIMTabActive && 'active'"
    //-   @click="changeActiveTab('im')") {{ $t('Chat') }}
      //- footer-mobile(v-if="$isMobile")
    div(
      v-for='(e,j) in roomlist' :key="j"
      v-if="$isMobile"
      :class="isIntroductionActive && 'active'"
      @click="changeActiveTab(j)") {{ e.menuTitle }}
    div(
      v-if="$isMobile && playback==true"
      :class="isSignActive && 'active'"
      @click="changeActiveTab('sign')") {{ $t('sign') }}
    div(
      v-if="$isMobile"
      :class="isScheduleActive && 'active'"
      @click="changeActiveTab('schedule')") {{ $t('schedule') }}
    div(
      :class="isIMTabActive && 'active'"
      @click="changeActiveTab('im')") {{ $t('Chat') }}
    
      //- footer-mobile(v-if="$isMobile")
    //- div(
    //-   v-if="$isMobile"
    //-   :class="isIntroductionActive && 'active'"
    //-   @click="changeActiveTab('Introduction')") {{ $t('Introduction') }}
    //- div(
    //-   v-if="$isMobile"
    //-   :class="isSignActive && 'active'"
    //-   @click="changeActiveTab('sign')") {{ $t('sign') }}
    //- div(
    //-   v-if="$isMobile"
    //-   :class="isExamActive && 'active'"
    //-   @click="changeActiveTab('exam')") {{ $t('exam') }}
    div(
      v-if="!$isMobile"
      :class="isExamActive && 'active'"
      @click="changeActiveTab('exam')") {{ $t('exam') }}
  div.info-region
    //- 消息发送及列表（PC端）
    comp-im#compimMobile(v-if="!$isMobile" ref="imMessage" v-show="isIMTabActive" )
    //- 消息发送及列表（移动端）
    comp-im-mobile#compimMobile.compimMobiles(
      v-if="$isMobile"
      ref="imMessage"
      v-show="isIMTabActive"
      :isMobileVerticalLayout="isMobileVerticalLayout"
      :isMobileHorizontalLayout="isMobileHorizontalLayout")
    //- 直播介绍栏
    div.menuclass(v-if='menudata' v-html='menudata.menuContent')
     //-签到
    //- comp-sign(v-if="isSignActive && layout_horizontal  " :playbacklists='playbacklists')
     //-会议日程
    comp-schedule(v-if="isScheduleActive && layout_horizontal")
    div.footer(v-if="$isMobile && isIMTabActive==false && isSignActive==false")
      a(target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=蜀ICP备20023024号")
        p.filing Copyright © 2021 蜀ICP备20023024号
    comp-info(v-if="isIntroductionActive")
    //-考试
    comp-esam(v-if="isExamActive && layout_horizontal")
   
</template>

<script>
import compIm from './comp-im-pc';
import compImMobile from './comp-im-mobile';
import compInfo from './comp-info.vue';
import compEsam from './comp-esam.vue';
import compSign from './comp-sign.vue';
import compSchedule from './comp-schedule.vue';
import footerMobile from '../comp-player/footer-mobile.vue';
import { mapState } from 'vuex';
import { LAYOUT } from '@/constants/room';

export default {
  name: 'compMessage',
  props: {
    isMobileVerticalLayout: {
      type: Boolean,
    },
    isMobileHorizontalLayout: {
      type: Boolean,
    },
    roomlist:{},
    playbacklists:{}
  },
  data() {
    return {
      activeTab: 'im',
      hideIntroductionComp: false,
      menudata:{},
      layout_horizontal:true,
      playback:false
    };
  },
  ...mapState({
    userInfo: 'userInfo',
    layout: 'layout',
  }),
  watch: {
    layout: {
      immediate: true,
      async handler() {
        if (this.layout == LAYOUT.HORIZONTAL){
          this.layout_horizontal = false;
        }
      }
    },
  },
  computed: {
    isIMTabActive() {
      return this.activeTab === 'im' || this.isMobileHorizontalLayout;
    },
    isIntroductionActive() {
      return !this.isMobileHorizontalLayout && this.activeTab === 'introduction';
    },
    isExamActive() {
      return this.activeTab === 'exam';
    },
    isSignActive() {
      return this.activeTab === 'sign';
    },
    isScheduleActive() {
      return this.activeTab === 'schedule';
    },
  },
  components: {
    compIm,
    compImMobile,
    compInfo,
    compEsam,
    compSign,
    footerMobile,
    compSchedule
  },
  created(){
     console.log(window.location.href);
     if(window.location.href.indexOf('playback')!=-1){
         this.playback=true
     }
  },
  methods: {
    changeActiveTab(tabName) {
      this.activeTab = tabName;
      this.menudata = this.roomlist[tabName];
      console.log(tabName);
    },
    // 处理用户退出直播间
    handleExit() {
      this.$refs.imMessage.quitGroup();
      this.$refs.imMessage.logout();
    },
  },
};
</script>
<style lang="stylus" scoped>
  .player-message-container
    width 100%
    height 100%
    position relative
    display flex
    flex-direction column
    .message-tab
      width 100%
      height 50px
      display flex
      align-items center
      justify-content space-around
      border-bottom 2px solid #f5f5f5
      color $grayFontColor
      font-size 16px
      font-weight 500
      z-index 1000
      background #fff
      border-radius 10px 10px 0 0
      div
        height 100%
        cursor pointer
        position relative
        display flex
        align-items center
        &.active
          color #446BE7
          &::after
            content ''
            width 50px
            height 3px
            position absolute
            bottom 0
            left 50%
            transform translate(-50%)
            background-color #446BE7
    .info-region
      width 100%
      height 0
      flex-grow 1
      overflow-y hidden
      overflow-x hidden
      display flex
      flex-direction column
      //margin-top 20rem

  //.mobile-container
   // position absolute
   // top 0
    //left 0
  .menuclass
     //text-align left
    // flex 1
     //width 100%
     //height 100%
     //margin-top 18rem
    // overflow scroll
     //padding 20px
    //text-align left

</style>

<style lang="less" scoped>
  @media screen and (min-width: 600px){
    .message-tab{
      width :100%;
      height: 50px;
      border-bottom: 0px;
    }
}
  @media screen and (max-width: 600px){
    .message-tab{
      width: 100%;
      height :50px;
      border-bottom: 0px;
      position: relative;
      top: 19rem;
   }
}
@media screen and (max-width: 600px){
    .mobile-container{
    position: absolute;
    top :0px;
    left: 0;
   }
}
@media screen and (min-width: 700px){
    .mobile-container{
      position: absolute;
      top :21rem;
      left: 0;
   }
}
@media screen and (max-width:600px){
  .menuclass{
    text-align:left;
     flex :1;
     width: 100%;
     height :100%;
     margin-top :18rem;
     overflow :scroll;
     padding :20px;
  }
     
    
}
.menuclass {
  ::v-deep {
    img{
      max-width: 100%;
      height: auto;
    }
    p, div{
      white-space:normal;
      word-break:break-all;
      width: 100%;
    }
  }
}


.footer .mobile-horizontal-layout{
  display: none;
}
  

.footer .mobile-vertical-layout{
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
 
  .filing{
  font-size: 12px;
}
}
.compimMobiles{
  margin-top: 0 !important;
}
</style>


<i18n>
{
	"en": {
		"Chat": "Chat",
		"Introduction": "Introduction",
    "exam":"exam",
    "sign":"sign",
    "schedule":"schedule"
	},
	"zh": {
		"Chat": "聊 天",
		"Introduction": "回放列表",
    "exam":"直播现场",
    "sign":"回放列表",
    "schedule":"直播现场"
	}
}
</i18n>
