/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 * npm i umi-request --save
 */
import axios from 'axios'
import StorageUtils from '@/putils/StorageUtils';
import { getUserToken, saveUserToken, clearAuthority } from '@/putils/authority';
import StringUtils from "@/putils/StringUtils";
import {CONFIGLIST} from "@/constants/localKeys"
import { Loading,Message } from 'element-ui'

let loadingInstance = null
let needLoadingRequestCount = 0;
function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        loadingInstance = Loading.service({
            fullscreen: true,
            lock: true,
            text: ''
        })
    }
    needLoadingRequestCount++
}

function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return;
    needLoadingRequestCount--;
    if (needLoadingRequestCount === 0) {
        loadingInstance.close();
    }
}
const arrayUrlExclude = [

];

const fileUrlExclude = [

];

const codeMessage = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
};

/**
 * 异常处理程序
 */
const errorHandler = error => {
    const { response } = error;
    tryHideFullScreenLoading()
    if (response && response.status) {
        const errorText = codeMessage[response.status] || response.statusText;
        const { status, url } = response;
        // notification.error({
        //   message: `请求错误 ${status}: ${url}`,
        //   description: errorText,
        // });
    } else if (!response) {
        // notification.error({
        //   description: '您的网络发生异常，无法连接服务器',
        //   message: '网络异常',
        // });
    }

    return response;
};

/**
 * 配置request请求时的默认参数
 */
const request = axios.create({
    errorHandler,
    // 默认错误处理
    timeout: 60000,
    credentials: 'include', // 默认请求是否带上cookie
});

// request拦截器, 改变url 或 options.
request.interceptors.request.use(async (req) => {
    //暂时注释、从本地获取token测试请求
     let lockey={}
     if(req.method == "get"){
        if(!StringUtils.isEmpty(req.params) && !StringUtils.isEmpty(req.params.userId)){
            lockey={
                userId:req.params.userId
             }
         }
     }
     if(req.method == "post"){
        if(!StringUtils.isEmpty(req.data)){
            // let reqData = JSON.parse(req.data);
            // console.log(reqData)
            if(!StringUtils.isEmpty(req.data.userId)){
                lockey={
                    userId:req.data.userId
                 }
             }
        }

     }
     let token = '';
     if(!StringUtils.isEmpty(req.params) && !StringUtils.isEmpty(req.params.token)){
        token = req.params.token;
     }else{
        token = getUserToken(lockey);
     }
    
    //如果有token 就走token逻辑
    //如果是刷新token接口，就直接过，不要拦截它！！！
    let headers = {};
    if (StringUtils.strIsNotContains(fileUrlExclude, req.url)) {

        headers = {
            // "Content-Type":"multipart/form-data; application/x-www-form-urlencoded; charset=utf-8",
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
        };
    } else if (StringUtils.strIsNotContains(arrayUrlExclude, req.url)) {

        headers = {
            "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
            'Authorization': `Bearer ${token}`,
        };
    } else if (token) {
        headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Authorization': `Bearer ${token}`,
        };
    } else {
        headers = {
            'Content-Type': 'application/json',
            // "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
            Accept: 'application/json',
        };
    }
    req.headers = headers;
    return req;
});

//第二个拦截器，为什么会存在第二个拦截器呢？就是因为第一个拦截器有可能返回Promise,那么Promise由第二个拦截器处理吧。之前因为这个问题跟umi提了issues。原来是我没搞明白。。。
request.interceptors.request.use(async (req) => {
    showFullScreenLoading()// 显示loading
    let lockey={}
     if(req.method == "get"){
        if(!StringUtils.isEmpty(req.params) && !StringUtils.isEmpty(req.params.userId)){
            lockey={
                userId:req.params.userId
             }
         }
     }
     if(req.method == "post"){
        if(!StringUtils.isEmpty(req.data)){
            // let reqData = JSON.parse(req.data);
            // console.log(reqData)
            if(!StringUtils.isEmpty(req.data.userId)){
                lockey={
                    userId:req.data.userId
                 }
             }
        }

     }
    let token = '';
    if(!StringUtils.isEmpty(req.params) && !StringUtils.isEmpty(req.params.token)){
       token = req.params.token;
    }else{
       token = getUserToken(lockey);
    };
    // console.log("token2",token)
    if (token) {
        //如果有token 就走token逻辑
        let headers = {};
        if (StringUtils.strIsNotContains(fileUrlExclude, req.url)) {
            headers = {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            };
        } else if (StringUtils.strIsNotContains(arrayUrlExclude, req.url)) {
            headers = {
                "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
                'Authorization': `Bearer ${token}`,
            };
        } else {
            headers = {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
            };
        }
        req.headers = headers;
        return req
    }
    return req;
});

request.interceptors.response.use(async (response, options) => {
    // 取消loading
    tryHideFullScreenLoading()
    const data = response.data;
    if (data.code == '403') {
        StorageUtils.removeSessionKey(CONFIGLIST.SESSION_WEB_TOKENINFO);
        StorageUtils.removeSessionKey(CONFIGLIST.SESSION_WEB_AESTOKEN);
        Message.error({
          type: 'error',
          message: "用户登录校验失败，请重新进入！",
        });
    }
    return data;
});
// request.interceptors.response.use(async (response, options) => {
//   const data = await response.clone().json();
//   return data;
// })

export default request;
