<template>
  <div class="main">
     <div class="headerCont">
      <compHeader />
       <div class="headerCont-main">
        <div>
          <h3>活动策划+直播服务+全权指导</h3>
          <p class="banner-item"></p>
          <h1>一站式直播解决方案</h1>
        </div>
        <button>了解更多</button>
      </div>
     </div>
      <div class="content">
        <div class="content-box">
          <div class="content-item">
            <div class="header">
              <img src="../assets/imgs/zhi.png" alt="">
              <h2>我们的产品</h2>
            </div>
            <div class="list-item">
               <div class="list-item-left">
                <div style="display: flex;align-items: center;">
                  <img style="width: 38px;" src="../assets/imgs/sp.png" alt="">
                   <span>云直播</span>
                </div>
                <p style="font-size: 14px;color:#666666 ;margin: 30px 0;">云直播提供极速、稳定、专业的云 端直播处理服务，根据业务的不同 直播场景需求，云直播提供了标准 直播、慢直播、快直播三种服务.</p>
                <div style="text-align: center;">
                  <img style="width: 150px;" src="../assets/imgs/zb1.png" alt="">
                  <p style="display: flex;align-items: center;justify-content: center;margin-top: 60px;color: #1472FF;">
                    了解更多
                    <img style="width: 20px;" src="../assets/imgs/jt.png" alt="">
                  </p>
                </div>
              </div>

               <div class="list-item-rigt">
                <div class="list-item-rigthbox" v-for="(item,index) in productlist" :key="index">
                  <div style="display: flex;align-items: center;">
                  <img style="width: 38px;" :src="item.img" alt="">
                   <span>{{item.name}}</span>
                </div>
                <p style="font-size: 14px;color:#666666 ;margin: 30px 0;">{{item.des}}</p>
                </div>
               
               </div>
            </div>
          </div>

          <div class="content-item">
            <div class="header">
              <img src="../assets/imgs/zhi.png" alt="">
              <h2>产品优势</h2>
            </div>
            <div class="list-supe">
                <div class="list-item-box" v-for="(item,index) in superioritylist" :key="index">
                  <div style="padding: 25px 25px 0 25px;box-sizing: border-box;margin-bottom: 30px;height: 340px;">
                    <img style="width: 150px;" :src="item.img" alt="">
                    <p style="font-size: 26px;font-weight: 600;margin: 30px 0;">{{item.name}}</p>
                    <p style="color: #666666;font-size: 15px;">{{item.des}}</p>
                     <p style="color: #666666;font-size: 15px;margin-top: 10px;">{{item.des1}}</p>
                  </div>
                  <div class="list-supe-footer">
                    <p style="font-size: 18px;margin-bottom: 10px;">{{item.footer}}</p>
                    <p> {{item.num}} </p>
                  </div>
                </div>
            </div>
          </div>

          <div class="content-item">
            <div class="header">
              <img src="../assets/imgs/zhi.png" alt="">
              <h2>999家企业正在合作</h2>
            </div>
            <div class="list-qiye">
                <img class="list-item-box" v-for="(item,index) in quyelist" :key="index" :src="item.img" alt="">
              
            </div>
          </div>

          <div class="content-item">
            <div class="header">
              <img src="../assets/imgs/zhi.png" alt="">
              <h2>我们的保证</h2>
            </div>
            <div class="list-pledge">
                <div class="list-item-box" v-for="(item,index) in pledgelist" :key="index">
                  <div class="list-item-top"> 
                    <img style="width: 70px;" :src="item.img" alt="">
                    <p>{{item.name}}</p>
                  </div>
                  <p style="font-size: 14px;color: #666666;">{{item.des}}</p>
                </div>
                
                
            </div>
          </div>
        </div>

      </div>
      <div class="footer-top">
           <div class="footer-top-box">
              <p>我们为您提供市面最新企业视频玩法</p>
              <p style="font-size: 20px;font-weight: 600;">全功能免费试用 | 全程线上支持 | 同类型客户类比参考</p>
              <p style="font-size: 14px;">您可以拨打咨询电话028-68850057或者直接预约产品演示</p>
              <p>
                <button style="color: #2572F5;background-color: #F5F8FF;">免费体验</button>
                <button style="color: #fff;margin-left: 15px;background-color: #2A68ED;">联系我们</button>
              </p>
           </div>
        </div>
      <compFooter/>
  </div>
</template>

<script>

import compHeader from '@/components/compHeader.vue'
import compFooter from '@/components/compFooter.vue'
import zb from '../assets/imgs/zb.png'
import an from '../assets/imgs/an.png'
import kuai from '../assets/imgs/kuai.png'
import wen from '../assets/imgs/wen.png'
import ks from '../assets/imgs/ks.png'
import wd from '../assets/imgs/wd.png'
import lc from '../assets/imgs/lc.png'
import one from '../assets/imgs/1.png'
import two from '../assets/imgs/2.png'
import three from '../assets/imgs/3.png'
import four from '../assets/imgs/4.png'
import five from '../assets/imgs/5.png'
import six from '../assets/imgs/6.png'
import seven from '../assets/imgs/7.png'
import eight from '../assets/imgs/8.png'
import nine from '../assets/imgs/9.png'
import ten from '../assets/imgs/10.png'
import eleven from '../assets/imgs/11.png'
import twelve from '../assets/imgs/12.png'
import thirteen from '../assets/imgs/13.png'
import fourteen from '../assets/imgs/14.png'
import fifteen from '../assets/imgs/15.png'
import sixteen from '../assets/imgs/16.png'
import seventeen from '../assets/imgs/17.png'
import eighteen from '../assets/imgs/18.png'
import nineteen from '../assets/imgs/19.png'
import twenty from '../assets/imgs/20.png'
import thirty from '../assets/imgs/21.png'
import forty from '../assets/imgs/22.png'
import fifty from '../assets/imgs/23.png'
import eighty from '../assets/imgs/24.png'
import sixty from '../assets/imgs/25.png'
import td from '../assets/imgs/td.png'
import jszc from '../assets/imgs/jszc.png'
import xsfw from '../assets/imgs/xsfw.png'
import qfmfw from '../assets/imgs/qfmfw.png'
import sbzg from '../assets/imgs/sbzg.png'
import zzfw from '../assets/imgs/zzfw.png'
export default {
  name: 'HomeView',
  components: {
    compHeader,
    compFooter
  },
  data(){
    return {
      productlist:[
        {img:zb,name:"云视频",des:"云视频可用于远程医疗、快速门诊、重症隔离区患者 家属沟通、保健宣传等方面，可接入医疗自有系统， 打造更便捷的医护体验."},
        {img:an,name:"更安全",des:"拥有安全防盗链、智能敏感词过滤、黑名单限制。保 证数据存储可靠、完整、保密，并且配有备份策略,登 记观看等."},
        {img:kuai,name:"更快",des:"快速部署，即点即播，满足从中小型企业视频云应用 需求。音画实时同步，延迟低。模拟现场感，隔空对 话很流畅."},
        {img:wen,name:"更稳定",des:"直播可容许百万级并发量观看，稳定流畅无卡顿。支 持电脑、手机、H5、平板等多种方式观看，参加率高、 完成率高."},
      ],
      superioritylist:[
        {img:ks,name:'超快速',des:"音画实时同步，延迟低",des1:"模拟现场感，隔空对话流程，视频不卡顿",footer:"服务器评价响应",num:"<100ms"},
        {img:wd,name:'超稳定',des:"直播可容许百万级并发量观看",des1:"保障网络信息和用户数据库安全",footer:"网络攻防切换",num:"<60s"},
        {img:lc,name:'超流畅',des:"高清直播依然流畅，拒绝卡顿",des1:"观看回放时，可自由调节播放片段",footer:"回放支持",num:"1080P"}
      ],
      quyelist:[
        {img:one},{img:two},{img:three},{img:four},{img:five},{img:six},{img:seven},{img:eight},{img:nine},{img:ten},{img:eleven},
        {img:twelve},{img:thirteen},{img:fourteen},{img:fifteen},{img:sixteen},{img:seventeen},{img:eighteen},{img:nineteen},{img:twenty},{img:thirty},
        {img:forty},{img:fifty},{img:eighty},{img:sixty}
      ],
      pledgelist:[
        {img:td,name:"强大售后团队",des:"强大的售前售后团队。配备专门的服务人员及技术 人员1v1服务确保客户碰到的问题第一时间予以解决"},
        {img:jszc,name:"完美技术支持",des:"产品手册、视频教程、常见问题解决方案等服务手朋 一应俱全，为您提供完整的技术指导和行业支持，让 您快速上手，轻松应对."},
        {img:xsfw,name:"迅速响应服务",des:"电话、QQ、工单、上门...售后人员随时为您提供迅 速响应服务，及时应对客户提出的切运营、技术难题."},
        {img:qfmfw,name:"全方位服务",des:"微信、QQ、电话等聊天工具全方位解答客户名种 操作、技术难题，确保客户系统安全稳定，bug及 时修复."},
        {img:sbzg,name:"平台搭建部署全程指导",des:"为各平台的搭建部署提供全程指导，即使创业小白， 中疗也能助您实现平台快速上线和运营."},
        {img:zzfw,name:"更多增值服务",des:"中疗将与您协同配合、提出优质的解决力案。持续 跟进用户反馈，满足您的多方位其他需求."},
      ]
    }
  },
  methods:{
    handleSelect(){

    }
  }
}
</script>

<style scoped lang="less">
.main{
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left !important;
  h2{
    font-size: 29px !important;
  }
  h1{
    font-size: 30px !important;
  }
  .content{
    flex: 1;
    background-color: #F9F9F9;
    padding: 20px 0;
    .content-box{
      width: 1200px;
      margin: 20px auto;
     .header{
      text-align: center;
     }
     .list-item{
      width: 100%;
      display: flex;
      justify-content: space-between;
    
     
      .list-item-left{
        margin-top: 20px;
        width: 304px;
        height: 500px;
        background-color: #fff;
        padding: 25px;
        box-sizing: border-box;
        border-radius: 10px;
        >div >span{
          font-size: 25px;
          font-weight: 700;
          margin-left: 5px;
          color: #1472FF;
        }
        
      }
      .list-item-left:hover{
        box-shadow:0 0 10px 1px rgb(213, 212, 212);
        }
      .list-item-rigt{
        width: 73%;
        display: flex;
        flex-wrap: wrap;
        .list-item-rigthbox{
          width: 418px;
          height: 240px;
          background-color: #fff;
          margin-left:20px;
          margin-top: 20px;
          padding: 25px;
          border-radius: 10px;
         box-sizing: border-box;
         >div >span{
          font-size: 25px;
          font-weight: 700;
          margin-left: 5px;
          color: #1472FF;
        }
        }
        .list-item-rigthbox:hover{
        box-shadow:0 0 10px 1px rgb(213, 212, 212);
        }
      }
     }
     
    }
    
  }
}
.content-item{
  margin-bottom: 50px;
  .list-supe{
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .list-item-box{
        width: 310px;
        height: 460px;
        background-color: #fff;
        // padding: 25px;
        box-sizing: border-box;
        border-radius: 15px;
        text-align: center;
      }
     }
  .list-qiye{
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
   
    .list-item-box{
        width: 220px;
        height: 70px;
        
        margin-top: 30px;
      }
  }
  .list-pledge{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    .list-item-box{
        width: 370px;
        // height: 170px;
        background-color: #fff;
        margin-top: 30px;
        padding: 25px 25px 38px 25px;
        box-sizing: border-box;
        
        border-radius: 10px;
        .list-item-top{
          text-align: center;
          margin-bottom: 15px;
          >p{
            font-weight: 600;
            font-size: 18px;
          }
        }
      }
  }
 
}
.headerCont-main {
  width: 1200px;
  margin: 80px auto;

  h3 {
    color: #fff;
    font-size: 22px;
  }

  h1 {
    color: #FFAB00;
  }

  .banner-item {
    width: 280px;
    height: 3px;
    background: #FFFFFF;
    margin: 20px 0;
  }
  button{
    margin-top: 50px;
    padding: 5px 18px;
    border-radius: 20px;
    background-color: #FFAB00;
  }
}
.headerCont{
  width: 100%;
  background: url(../assets/imgs/banner.png) no-repeat;
  background-size: 100%;
  z-index: 1;
  // height: 400px;
  position: relative;
}
.list-supe-footer{
 display: flex;
 flex-direction: column;
justify-content: center;
  background: url(../assets/imgs/supefooter.png) no-repeat;
  background-size: 100% 100%;
  color: #fff;
  height: 100px;
}
.footer-top{
    width: 100%;
    background: #2079FA;
    color: #ffffff;
    text-align: center;
    .footer-top-box{
        padding:20px 0;
        line-height: 45px;
        button{
          padding: 8px 18px;
          border-radius: 20px;
          border: none;
        }
    }
  }
</style>
