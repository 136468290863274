import request from '@/putils/request.js';
const urlList = {
  liveStreamingRoom: '/live/streaming/admin',  //直播房间详情
  sendSms: '/user/api/account/send-sms',  //发送短信验证码
  registerWeb: '/server/api/registerWeb',  //注册账号
};

/**
 * 直播房间详情
 * @param params
 * @returns {Promise<any>}
 */
export function getLiveStreamingRoom(params) {
  return request(urlList.liveStreamingRoom, {
    method: 'get',
    params: params,
  });
}

/**
 * 银联
 * @param params
 * @returns {Promise<any>}
 */
export function unionCreateOrder(params) {
  return request('/api/cicc/pay/unionCreateOrder', {
    method: 'post',
    params: params,
  });
}

/**
 * 验证实名
 * @param params
 * @returns {Promise<any>}
 */
export function getIdentityStatus(params) {
  return request('/ygetway/user/api/identity/getIdentityStatus', {
    method: 'get',
    params: params,
  });
}


//获取信息
// axios文档
export function registerInfo(params) {
  return request('/api/user/watch/filter/register_info', {
    method: 'post',
    data: params,
  });
}

//获取观看限制
export function filterinfo(params) {
  return request('/api/live/watch/filter/info', {
    method: 'post',
    data: params,
  });
}
//获取直播间详情
export function getRoodDetail(params) {
  return request('/api/live/streaming/room/detail', {
    method: 'get',
    params: params,
  });
}

//获取菜单栏列表
export function getRoomList(params) {
  return request('/api/live/room/menu/list', {
    method: 'get',
    params: params,
  });
}

//查询房间回放信息
export function playbackList(params) {
  return request('/api/live/room/playback/list/top10', {
    method: 'get',
    params: params,
  });
}
