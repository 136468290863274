import request from '@/putils/request.js';
export default {
    //登录待定
    login: ({ username, password }) => {
        console.log({ username, password });
    },
    //获取用户信息、登录权限
    getUserInfo: (params) => {
        return request('/api/user/user/info', {
            method: 'get',
            params
        })
    }
}
