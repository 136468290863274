<template>
    <div class="box">
        <div class="showVote" @click="showVoteDialog">
            <img width="15px" src="../../../../assets/img/guan.png" alt="">
        </div>
        <div class="header">
            {{swictVote.msgContent.title}}
        </div>
        <div class="content">
            <div v-for="(item, index) in swictVote.msgContent.voteInfo" :key="item.idx">
                <div style="font-size: 16px;display: flex;margin-bottom: 10px;align-items: baseline;">
                    <span style="color: red;">*</span>
                <span style="margin-right: 5px;">{{index+1}}.</span>
                <div>
                    <span style="margin-right: 10px;">{{item.choiceType=="radio_type" ? '[单选]': '[多选]'}}</span>
                     <span>{{ item.question }}</span>
                </div>
                </div>
                <div class="content-radio">
                 
                  
                    <el-radio-group v-model="checkboxtype[index]"   v-if="item.choiceType=='radio_type'">
                        <el-radio v-for="(j, k) in item.voteAnswers"  @change="radiotypeBtn(item,j)" :value="j" :label="j.id" :key="j.id">{{ j.answerItem }}
                        </el-radio>
                    </el-radio-group>
                
                    <el-checkbox-group v-model="currentLine"  @change="checkboxBtn"  v-if="item.choiceType=='checkbox_type'" >
                      <el-checkbox v-for="(j, k) in item.voteAnswers"   :value="j.id" :label="j.id" :key="j.id">{{ j.answerItem }}</el-checkbox>
                    </el-checkbox-group>
                
                </div>
            </div>

            <!-- <div v-for="(item, index) in checkboxtype" :key="index">
                <p style="font-size: 16px;">
                <span style="margin-right: 10px;">{{item.choiceType=="radio_type" ? '[单选]': '[多选]'}}</span>
                <span>{{ item.question }}</span>
                </p>
                <div class="content-radio">
                    <el-checkbox-group v-model="checkList">
                      <el-checkbox  :label="j.answerItem" v-for="(j, k) in item.voteAnswers" >{{ j.answerItem }}</el-checkbox>
                    </el-checkbox-group>
                </div>
            </div> -->
        </div>
        <div class="footer">
            <button class="buttom" style="background-color: #409eff;"  @click="submit" >提交答案</button>
            <button class="buttom" v-show="switchButtom"
                style="border: 1px solid #409eff;color: #409eff;background-color: #fff;">放弃投票</button>
        </div>
        
    </div>
</template>

<script>
import {  voteCommit} from '@/api/vote';
import { mapActions,mapState,mapGetters } from 'vuex';
import StorageUtils from '@/putils/StorageUtils';
import { ROOM_USER_VOTE_COMMET } from "@/constants/localKeys"
import { Message } from 'element-ui';

export default {
    props:[
        'swictVote'
    ],
    data() {
        return {
            currentLine: [],
            disabled:true,
            switchButtom:false,
            commit:{
                commitInfo:[{
                    choiceType:"",
                    idx:0,
                    question:"",
                    voteAnswers:[{
                        answerItem:"",
                        choiceTimes:0,
                        id:""
                    }]
                }],
                roomNo:"",
                thirdUserId:"",
                voteId:"",
                userId:""
            },
            lockey:{
                userId:""
            },
            radiotype:[],
            checkboxtype:[],
            checkbox:[],
            radiobox:[],
            arr:[],
            a:'',
            call_voteLists:{}
        }
    },
    computed: {
      ...mapState({
        userInfo: 'userInfo',
        thirdUserId:'thirdUserId'
      }),
      ...mapGetters(["get_voteList"]),
    },
    created(){
        this.lockey.userId=this.userInfo.userId
    },
    methods:{
        ...mapActions(['call_voteList']),
        //点击单选
        radiotypeBtn(val,j){
            console.log(val)
            console.log(j.id);
            if(val.idx!=undefined){
                this.radiobox[val.idx] = j.id 
            }
           
      
        },
        checkboxBtn(val){
                // console.log(val);
                this.checkbox=[]
                val.forEach((item)=>{
                    let value=item
                    this.checkbox.push(value)
                })
              console.log(this.checkbox);
        },
        submit(){
            //  console.log(this.checkbox);
            //  console.log(this.checkboxtype)
            console.log(this.swictVote.msgContent.voteInfo)
            //console.log(Object.keys(this.checkboxtype).length);
            const checbo=this.checkboxtype.concat(this.checkbox)
            console.log(Object.keys(checbo).length)
          if(Object.keys(checbo).length>=this.swictVote.msgContent.voteInfo.length){
            let commitInfo = [];
            this.swictVote.msgContent.voteInfo.forEach((i,z) => {
                let chevote = [];
                i.voteAnswers.forEach((x,j) => {
               
                    for(let t of checbo){
                       
                        if(t != undefined && t== x.id){
                            let findInfo = { answerItem:x.answerItem,answerIdx:x.answerIdx,  id:x.id};
                            chevote.push(findInfo)
                        }
                    }
                });
                let pinfo = {choiceType:i.choiceType,  idx:i.idx, question:i.question,voteAnswers:chevote};
                commitInfo.push(pinfo);
            });
            let dataCommit = {
                roomNo:this.swictVote.msgContent.roomNo,
                voteId:this.swictVote.msgContent.id,
                userId:this.userInfo.userId,
                thirdUserId:this.thirdUserId,
                commitInfo:commitInfo
            }
          console.log(dataCommit);
          voteCommit(dataCommit).then(res=>{
             if(res.code=='000'){
                StorageUtils.setLocalItem(ROOM_USER_VOTE_COMMET+'_'+dataCommit.voteId,this.lockey, true);
                this.$parent.fatherMethod();
                Message({
                type: 'success',
                message: '提交成功',
          });
             }else if(res.code=='999'){
                for(let i of this.get_voteList){
                i.msgContent.status='stop_publish'
              }
              this.call_voteList(this.get_voteList)
                this.$parent.fatherMethod();
                Message({
                type: 'warning',
                message: '投票已结束',
          });
             }
          })
          .catch((err) => {
          console.log(err);
          
        });
             
          }else{
            Message({
                 type: 'error',
                 message: '请完成必填题目后重试',
             });
            
          }
         
            
        },
        showVoteDialog(){
            this.$parent.fatherMethod();
        }
    }


}
</script>

<style scoped lang="scss">
.box {
    display: flex;
    flex-direction: column;
    padding: 0px !important;
}

.header {
     text-align: center;
    width: 300px;
    margin: 0 auto;
    
}

.content {
    flex: 1;
    overflow-y: scroll;
    padding: 0 40px;
    // overflow-x: hidden;
    // &::-webkit-scrollbar{
    // width: 6px;  /*滚动条宽度*/
    //  height: 12px;  /*滚动条高度*/
    // }
}

.content-radio {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

/* .content::-webkit-scrollbar{display:none} */
.footer {
    display: flex;
    justify-content: space-around;
    border-top: 2px solid rgb(212, 211, 211);
    padding: 15px 0;
}

.buttom {
    border-radius: 20px;
    width: 78px;
    text-align: center;
    height: 32px;
    line-height: 25px;
    border: none;
    color: #fff;
}

.el-radio {
    width: 90%;
    border: 1px solid #d7d5d5;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 8px;
    display: flex;
}
::v-deep .el-radio__label{
   word-break: break-word;
   white-space: normal;
}
::v-deep .el-checkbox__label{
    word-break: break-word;
   white-space: normal;
}
::v-deep .el-checkbox{
    width: 90%;
    border: 1px solid #d7d5d5;
    padding: 10px;
    border-radius: 8px;
}
.el-checkbox{
    display: flex;
}
.showVote{
    position: absolute;
    right: 10px;
    top: 8px;
}

</style>
