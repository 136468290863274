<template lang="pug">
  div#papp(:class="[layoutClassName]" ref="papp" v-loading="loadingPlayer" element-loading-background="rgba(0, 0, 0, 0.8)")
    div#my-container(v-if="!$isMobile")
      div#content
        div#header
          comp-header(v-if="!$isMobile")
          comp-header-mobile(v-if="$isMobile && layout == layout_player")
        div#player
          comp-player(v-if="!$isMobile")
          comp-player-mobile(v-if="$isMobile" ref="player")
        div#footer(v-if="!$isMobile")
          comp-tabs(:roomlist='roomlists' :playbacklists='playbacklist' style={height:'100%'})
        div.fliiter
          a(target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=蜀ICP备20023024号")
            span.filing Copyright © 2021 蜀ICP备20023024号
      div(style={width:'3%'})
      div#message
          //- div(style={backgroundColor:'#446BE7',width:'97%',height:'30px',position:'absolute'})
            comp-user-info#comp-user-info
          comp-message#message-container(ref="message"
            :isMobileVerticalLayout="isMobileVerticalLayout"
            :isMobileHorizontalLayout="isMobileHorizontalLayout")
      comp-foom#vote(v-if="voteSwi" :swictVote='swictVote')
      
    div#my-container(v-if="$isMobile")
      div#header
        comp-header(v-if="!$isMobile")
        comp-header-mobile(v-if="$isMobile && layout == layout_player")
      div#content
        div#player
          comp-player(v-if="$isMobile")
          //- comp-player-mobile(v-if="$isMobile" ref="player")
        div#message
          //- div(style={backgroundColor:'#446BE7',width:'97%',height:'30px',position:'absolute'})
            comp-user-info#comp-user-info
          comp-message#message-container(ref="message"
            :isMobileVerticalLayout="isMobileVerticalLayout"
            :isMobileHorizontalLayout="isMobileHorizontalLayout"
            :roomlist = 'roomlists'
            :playbacklists='playbacklist'
            )
        div#footer(v-if="!$isMobile")
          comp-tabs(:roomlist = 'roomlists')
        comp-foom#vote(v-if="voteSwi" :swictVote='swictVote')
    copmDialog(:dialogshows='dialogshow')
</template>

<script>
import compUserInfo from '@/components/pusher/comp-header/comp-user-info.vue';//直播老师userInfo
import compHeader from '@/components/player/comp-header/index';
import compHeaderMobile from '@/components/player/comp-header/index-mobile';
import compPlayer from '@/components/player/comp-playback/index';
import compPlayerMobile from '@/components/player/comp-player/index-mobile';
import compMessage from '@/components/player/comp-message';
import compTabs from '@/components/player/comp-player/playbackfooter.vue';
import compFoom from '@/components/player/comp-player/comp-foom/index.vue';
import copmDialog from '@/components/player/copm-dialog.vue';
import layout from '@/layout.js';
import { Message, Loading } from 'element-ui';
import { MESSAGE_DURATION } from '@/constants/message';
import {VisitorSwitch,RoomFilterTypes,RoomStatus,RoomType} from "@/constants/render";
import { getUserToken, saveUserToken, clearAuthority } from '@/putils/authority';
import {
  SET_IS_SUPPORT_WEBRTC,
  UPDATE_LINE_TYPE,
} from '@/constants/mutation-types';
import { LINE_TYPE,LAYOUT} from '@/constants/room';
import liveRoom from '@/config/liveRoomConfig';
import StringUtils from '@/putils/StringUtils'
import { mapState ,mapGetters} from 'vuex';
import {playbackList } from '@/api/player/player';
import TRTC from 'trtc-js-sdk';
import liveConfigInfo from '@/config/liveConfigInfo';
import { ROOM_LAST_PAGE , ROOM_USER_VOTE_COMMET} from "@/constants/localKeys"
import StorageUtils from "@/putils/StorageUtils"
export default {
  name: 'player',
  mixins: [layout,liveRoom,liveConfigInfo],
  data() {
    return {
      // showFilter:false,
      registerName:"",
      registerPhone:"",
      loadingPlayer:true,
      roomlists:[],
      roomNo:"",
      registerDataList:[],
      layout_player:LAYOUT.HORIZONTAL,
      lockey:{
        userId:"",
        roomId:""
      },
      voteData:{},
      voteSwi:false,
      playback:{
        userId:"",
        playSort:"",
        roomNo:""
      },
      playbacklist:[],
      dialogshow:false
   
    };
  },
  computed: {
    ...mapState({
      layout:"layout",
      userInfo: 'userInfo',
      userSig: state => state.userInfo.userSig,
      sdkAppId: state => state.sdkAppId,
      shareUserSig: state => state.userInfo.shareUserSig,
      shareUserId: state => `share_${state.userInfo.userId}`,
      playerDomain: "playerDomain",
      roomInfo: "roomInfo",
      anchorUserInfo: "anchorUserInfo",
      // voteList: 'voteList',
    }),
    ...mapGetters(["get_voteList","get_playback"]),
  },
   watch:{
    get_playback(data){
       if(data.length>0){
        this.dialogshow=true
       }
    }
   },
  components: {
    compUserInfo,
    compHeader,
    compHeaderMobile,
    compPlayer,
    compPlayerMobile,
    compMessage,
    compTabs,
    compFoom,
    copmDialog
  },
  methods: {
    // 处理是否支持 webRTC
    async handleSupportWebRTC() {
      const supportResult = await TRTC.checkSystemRequirements();
      const { isWebRTCSupported, isH264DecodeSupported } = supportResult.detail;
      if (!isWebRTCSupported || !isH264DecodeSupported) {
        this.$store.commit(SET_IS_SUPPORT_WEBRTC, false);
        this.$store.commit(UPDATE_LINE_TYPE, LINE_TYPE.CDN);
      }
    },
    // 处理页面数据
    handlePlayerInfo() {
      // let anchorUserInfo = configInfo.anchorUserInfo;
      // if (this.sdkAppId === '' || this.secretKey === '') {
      //   alert(`${this.$t('basic.Please configure your SDKAPPID')}\r\n\r\nconfig/liveConfigInfo.js`);
      // }
      //todo 初始化userSig
      // const generator = new LibGenerateTestUserSig(sdkAppId, secretKey, expireTime);
      // const userSig = generator.genTestUserSig(userInfo.userId);
      // // 注意：请优先设置 sdkAppId 信息
      // this.$store.commit(SET_SDK_APP_ID, this.sdkAppId);
      // this.$store.commit(SET_USER_SIG, this.userSig);
      // this.$store.commit(SET_PLAYER_DOMAIN, this.playerDomain);
      // this.$store.commit(SET_ROOM_ID, this.roomInfo.roomId);
      // this.$store.commit(SET_ROOM_NAME, this.roomInfo.roomName);
      // this.$store.commit(SET_ANCHOR_USER_ID, anchorUserInfo.userId);
      // this.$store.commit(UPDATE_USER_INFO, {
      //   userId: userInfo.userId,
      //   userName: userInfo.userName,
      //   userAvatar: userInfo.userAvatar,
      // });
    },
    // 退出直播间
    // async handleExit() {
    //   // todo: 处理退出直播间
    // },
    async fatherMethod(){
       this.voteSwi=false
    },
    // 退出登录
    async handleLogout() {
      // todo: 处理退出登录
    },
    //查询回放
    getBlayback(){
      playbackList(this.playback).then(res=>{
        let datse=Date.now()
      if(res.data.playbackTimeOut!=-1 && res.data.playbackTimeOut<datse){
          Message({
        type: 'warning',
        message: '该回放房间到期',
        duration: MESSAGE_DURATION.LONG,
         });
         this.routerWatch()
      }
         if(res.data=='switch_close'|| res.data.roomStatus=="live_ing"){
          this.routerWatch()
         }
         if(res.code=='000'){
            this.playbacklist=res.data.playbackVideos
            
         }
      }).catch(err=>{
        console.log(err);
      })
    },
    routerWatch(){
      this.$router.push({
        path: 'watch',
        query: {
          userId: this.$route.query.userId,
          roomId: this.$route.query.roomId,
        }
      });
    },
    toWatchUrl() {
        let query = this.$route.query;
        let lastPage = StorageUtils.getSessionCache(ROOM_LAST_PAGE, {roomId:query.roomId});
        this.$router.replace({ name: lastPage.path,query });
    }
  },
  async created() {
    // this.joinGroup()
    let {roomId,userId, tuid} =  this.$route.query;
           this.playback.roomNo=roomId
           this.playback.userId=userId
    this.getBlayback()
    this.getCacheUserInfo(this.$route.query.userId);
    //判断是否有用户信息
    let that = this;
    
    this.lockey.userId=this.userInfo.userId
    let token = getUserToken(this.lockey);

    // if ((StringUtils.isEmpty(this.roomInfo.visitorSwitch) ||
    //   StringUtils.isObjEqual(VisitorSwitch.ROOM_SWITCH_CLOSE,this.roomInfo.visitorSwitch)) && StringUtils.isEmpty(token)){
    //   Message({
    //     type: 'warning',
    //     message: '游客暂无观看权限！',
    //     duration: MESSAGE_DURATION.LONG,
    //   });
    //   setTimeout(function () {
    //     that.routerWatch();
    //   },1000)
    //   return false;
    // }

     this.roomlists = await this.setStateLiveRoomInfo();
    // 判断是否支持 webrtc, 不支持 webrtc 的网页采用【线路三：标准直播】播放，且不可切换线路
    this.handleSupportWebRTC();
    // 处理页面数据
    this.handlePlayerInfo();
    if(this.roomInfo.lineType == LINE_TYPE.LEB){
      this.$store.commit(UPDATE_LINE_TYPE, LINE_TYPE.LEB);
      
    }

    // 处理监听事件
    this.loadingPlayer = false;
    this.$eventBus.$on('exit', this.handleExit);
    this.$eventBus.$on('logout', this.handleLogout);
    this.$eventBus.$on('showLoginCard', this.handleShowLoginCard);
    document.title=this.roomInfo.roomName
  },
  beforeDestroy() {
    this.$eventBus.$off('exit', this.handleExit);
     this.$eventBus.$off('logout', this.handleLogout);
    this.$eventBus.$off('showLoginCard', this.handleShowLoginCard);
  },

  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL)
     
      // window.addEventListener('popstate', this.toWatchUrl)
    }
  },
  destroyed() {
    // window.removeEventListener('popstate', this.toWatchUrl)
  },


};
</script>

<style scoped>
@media screen and (max-width: 900px){
  #papp.app-layout {
  background-image: none;
}
}
@media screen and (min-width: 900px){
  #papp.app-layout {
    background-image: url(@/assets/img/beijing.jpg);
    position:fixed;
    background-size: 100% 100%;
}
}
</style>

<style lang="stylus" scoped>
@import '~assets/style/black-element-ui.styl';

#papp {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: auto;
  font-size: 16px;
}


#papp.app-layout
  color $fontColor
  box-sizing border-box
  width 100%
  height 100vh
  #header
    width 100%
    height 90px
    background-color #fff
    border-radius 10px 10px 0 0 
  #content
    width 100%
    //flex 1
    //height 50rem
    #player
      //width 54rem
      //height 40rem
      flex-grow 1
      background-color $backgroundColor
      //margin 0 30px
      background-color: #0B1A3B
      box-sizing border-box
      //width calc(100% - 18.3% )
      border-radius 5px
  #message
      //height 40rem
      width 30%
      //max-height 50rem
      //margin-top 7rem
      min-width 200px
      max-width 360px
      //margin-right 20px
      box-sizing border-box
      margin-bottom: 11px
      background-color $IMThemeColor
      border-radius 10px
      box-shadow: 0px 0px 2px #ccc
      #comp-user-info
          width 100%
          line-height 200%
          margin-left 30px
        .self-info
          margin 0
          padding 0
        #message-container
          //height 90%

  #bottom
    height 21px
    width 100%
    position absolute
    bottom 0
    background-color #0B1A3B
// 移动端竖屏布局
#papp.mobile-vertical-layout
  background-color $IMThemeColor
  color $fontColor
  display flex
  flex-direction column
  #header
    width 100%
    max-height 130px
  #content
    flex-grow 1
    display flex
    flex-direction column
    #player
      width 100%
      height 260px
      background-color $backgroundColor
      z-index 1000
      #message
        background-color $IMThemeColor
        flex-grow 1
        width 100vw
        position relative
        #comp-user-info
          width 100%
          line-height 200%
          margin-left 10%
        #message-container
          height 50%
          position absolute
          top 120%
// 移动端横屏布局
#papp.mobile-horizontal-layout
  color $fontColor
  #header
    width 100%
    position absolute
    top 0
    left 0
    z-index 10
    transform translateZ(100px)
  #content
    width 100%
    height 100%
    #player
      width 100%
      height 100%
      background-color $backgroundColor
    #message
      position absolute
      bottom 0
      left 0
      width 100%
      height 70%
      pointer-events none
      transform translateZ(100px)
    #vote
      height 24rem
 .a
    position fixed
    left 0
    top 0
    z-index 999
    width 100%
    height 100%
    background-color rgba(0 0 0 0.8)
   .ab
    position: absolute
    left 50%
    top 50%
    width 500px

    transform translate(-50%,-50%)
    background-color #fff
    border-radius 10px
 .box
    padding 15px
    text-align initial
    .msg
     margin 25px
     span
      display inline-block
      width 85px
      text-align: right
      margin-right 5px
     input
       text-indent 5px
       padding 8px 2px
       width 70%
       border-radius: 2px
       outline: none
       border 1px solid #ccc
   .btn
     display: flex
     justify-content: space-around
     margin-top 50px
     button
      padding 2px 10px
      width 65%
      height 35px
      border-radius: 20px
      background-color: #1392e4
      border: 0px
      color: #fff
      font-size: 16px
    
</style>
<style scoped>
#footer{
  background-color: #fff;
  padding: 0 30px;
  /* margin: 0 30px; */
  /* width: 81.7%; */
  border-radius:0 0 10px 10px;
  height: 200px;
  /* min-height: 19rem; */
  /* max-width: 54rem; */
  text-align: left;
}

@media screen and (min-width: 900px){
  #my-container{
  overflow: scroll;
  height: 100%;
  display: flex;
  /* padding: 0 5rem;
  justify-content: center; */
  padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    justify-content: space-between;
    padding-top: 30px;
}
  }
  @media screen and (max-width: 900px){
  #my-container{
  overflow: scroll;
  height: 100%;
}
  }
@media screen and (min-width: 992px){
  #my-container {
    width: 970px
}
}
@media screen and (min-width: 1200px){
  #my-container {
    width: 1350px;

}
}
@media screen and (max-width: 700px){
  #papp{
    overflow-x: scroll;
    width: 900px;
    height: 100%;
}
}
@media screen and (max-width: 700px){
  #papp.mobile-vertical-layout {
    overflow-x: scroll;
    width: 100%;
    height: 100%;
}
}

#my-container::-webkit-scrollbar{
display:none;
}
.fliiter{
  margin: 20px;

}
.filing{
  color: #fff;
}
@media screen and (max-width: 700px){
  #vote{
  width: 23rem;
  height: 28rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 11;
  transform: translate(-50%, -50%);
  display: flex;
  background-color: rgb(250, 250, 250);
  border-radius: 10px;
  z-index: 1000;
  border: 1px solid rgb(165, 164, 164);

}
}
@media screen and (min-width: 700px){
  #vote{
  width: 410px;
  height: 395px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 11;
  transform: translate(-50%, -50%);
  display: flex;
  background-color: rgb(250, 250, 250);
  border-radius: 10px;
  border: 1px solid rgb(165, 164, 164);
}
}

</style>
