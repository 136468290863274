<template>
  <div class="exan-container">
    <div style="text-align: center;width: 120px;" v-for="(item,index) in playbacklists" :key="index" @click="playlist(item)">
          <img src="../../../../src/assets/img/back.png" alt="">
           <p class="playname">{{item.playFileName}}</p>
     </div>
  
  </div>
</template>

<script>
import {
  UPDATE_VIDEO_URL
} from '@/constants/mutation-types';

export default {
  props: {
    roomlist:{},
    playbacklists:{}
  },
  

  methods: {
    playlist(val){
        //  this.$bus.emit('parName',val.videoURL)
         this.$store.commit(UPDATE_VIDEO_URL,val.videoURL);
      } 
  

  },
};
</script>

<style lang="less" scoped>

@media screen and (max-width: 600px) {
    .exan-container {
  width: 100%;
  position: absolute;
  // bottom: -10rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 18rem;
} 
}

@media screen and (min-width: 700px) {
    .exan-container {
  width: 100%;
  position: absolute;
  // bottom: -10rem;
  display: flex;
  flex-wrap: wrap;
  // margin-top: 18rem;
} 
}


.playname{
  width: 90%;
  margin: 0 auto;
overflow: hidden;
display: -webkit-box;
text-overflow: ellipsis;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2
}
// img {
//   width: 23rem;
//   margin-top: 5rem;
// }

@media screen and (max-width: 900px) {
  .mobile-horizontal-layout {
    .exan-container {
      display: none;
    }
  }
}

@media screen and (min-width: 900px) {
  .mobile-horizontal-layout {
    .exan-container {
      display: none;
    }
  }
}
</style>
