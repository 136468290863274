/*
 * @Description: 这里是 TUIPlayer 应用的基础信息配置
 */
import { getLiveTencentGetTxConfig, getLiveStreamingDetail } from '@/api/lvie';
import StorageUtils from '@/putils/StorageUtils';
import StringUtils from "@/putils/StringUtils";
import { SET_SDK_CACHE, USER_DATA_KEY,USER_SHOW_FILTER } from '@/constants/localKeys';
import { mapState } from 'vuex';
import {
  SET_ANCHOR_USER_ID,
  SET_PLAYER_DOMAIN,
  SET_ROOM_ID, SET_ROOM_INFO,
  SET_ROOM_NAME,
  SET_SDK_APP_ID,
  STATE_SHOW_FILTER,
  UPDATE_ROOM_NAME,
  UPDATE_USER_INFO,
} from '@/constants/mutation-types';
import { saveUserToken } from '@/putils/authority';
import $Api from '@/api';
import { Message } from 'element-ui';
import { MESSAGE_DURATION } from '@/constants/message';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      sdkAppId:'sdkAppId',
      userInfo: 'userInfo',
      roomInfo: 'roomInfo',
      anchorUserInfo: 'anchorUserInfo',
      playerDomain: 'playerDomain',
      showFilter: 'showFilter',
    }),
  },
  methods: {
    init() {
      this.getLiveTencentGetTxConfigSession();
    },
    /**
     * 初始化播放配置
     */
    getLiveTencentGetTxConfigSession() {
      console.log('初始化播放配置------------------');
      if (this.getCacheSdkAppId()){
        console.log('初始化播放配置-- sdkAppId 已存在 ----------------');
        return true;
      }
      return new Promise((resolve, reject) => {
        //你的逻辑代码
        getLiveTencentGetTxConfig({})
          .then(res => {
            if (res.code = '000') {
              let sdkCache = {
                sdkAppId:res.data.sdkappid,
                playerDomain:res.data.playUrl,
              }
              this.$store.commit(SET_SDK_APP_ID, res.data.sdkappid);
              this.$store.commit(SET_PLAYER_DOMAIN, res.data.playUrl);
              // 注意：请优先设置 playerDomain 信息
              let lockey={}
              StorageUtils.setSessionItem(SET_SDK_CACHE, lockey, sdkCache);
              resolve(res.data)
            } else {
              console.log('初始化播放配置---失败');
              console.log(res.msg);
              reject(null);
            }
          })
          .catch(err => {
            reject(null);
            console.log(err);
          });
      });
    },
    getCacheSdkAppId(){
       let sdkCache =  StorageUtils.getJsonSessionItem(SET_SDK_CACHE);
       if (!StringUtils.isEmpty(sdkCache)){
           this.$store.commit(SET_SDK_APP_ID, sdkCache.sdkAppId);
           this.$store.commit(SET_PLAYER_DOMAIN, sdkCache.playerDomain);
           return true
       }
       return  false;
    },
    getCacheUserInfo(userId){
        let lockey={userId:userId}
        let userCache =  StorageUtils.getJsonSessionItem(USER_DATA_KEY,lockey);
        if (!StringUtils.isEmpty(userCache)){
          this.setStateData(userCache)
        }else{
          // this.routerLogin();
        }
    },
  async getCacheRoomInfo(roomId,userId){
      if(!StringUtils.isEmpty(roomId)){
        let dres= await new Promise((resolve, reject) => {
          getLiveStreamingDetail({roomNo:roomId}).then(res=>{
              if(res.code == '000'){
                resolve(res.data);
              }else{
                reject(null);
                Message({
                  type: 'error',
                  message: res.msg,
                  duration: MESSAGE_DURATION.LONG,
                })
              }
          }).catch(err=>{
            console.log(err)
            reject(null);
          });
        });
        this.setStateRoomData(dres)
        if(!StringUtils.isEmpty(userId)){
          this.setUserLoacData(userId,dres)
        }

      }
    },
    /**
     * 获取用户信息、房间信息
     */
    async getUserInfoFunc() {
      const { isv3, token } = this.$route.query;
      // 判断用户信息（用户权限、token）
      if (isv3 == '1' && !StringUtils.isEmpty(token)) {
        this.loginIsShow = !this.loginIsShow;
        // 发送直接登录请求
        const res = await $Api.userApi.getUserInfo({token:token});
        if (res.code == "000") {
          this.setStateData(res.data)
          let lockey={userId:res.data.userId}
          saveUserToken(token,lockey)
          StorageUtils.setLocalItem(USER_DATA_KEY, lockey, res.data)
          let pquery={
            userId: res.data.userId,
            roomId: res.data.roomNo,
          };
          //用户角色 startv:主播 | watch:观众 | major|管理
          switch (res.data.userRole){
            case "startv":
              this.routerCheck(pquery);
              break
            case "watch":
              this.routerPlayerurl(pquery);
              break
            case "major":
              //这里是值对应的处理
              this.routerCheck(pquery);
              break
            default:
              //这里是没有找到对应的值处理
              console.log("没有该类型")
              console.log(pquery)
              this.routerIndex()
              break
          }
        }else{
          console.log(res.msg);
          Message({
            type: 'error',
            message: res.msg,
            duration: MESSAGE_DURATION.LONG,
          });
          this.routerLogin();
        }
      }else {
        this.routerIndex();
      }
    },
    setStateData(data){
       
      if(StringUtils.isEmpty(data.userId)){
        return false;
      }
      let userInfo = {
        userId: data.userId ? data.userId:"",
        imUserId: data.userId,
        userRole:data.userRole ? data.userRole:"",
        userName: data.userName?data.userName:'',
        userAvatar: data.userAvatar?data.userAvatar:'',
        userSig: data.userSig? data.userSig:'',
        shareUserId:data.userId? "share_"+data.userId:'',
        shareUserSig: data.shareUserSig?data.shareUserSig:'',
      };
      let roomInfo = {
        roomId: data.roomNo,
        roomName: data.title,
        logoCover: data.logoCover,
        bgImg: data.bgImg,
        // filterType:data.filterType,
        watchFilter:data.watchFilter,
         //filterType:register_filter,
        roomStatus:data.roomStatus ? data.roomStatus : data.status,
        roomType:data.roomType ? data.roomType : data.type,
        lineType:data.lineType ? data.lineType : data.lineType,
        startTime:data.startTime,
        visitorSwitch:data.visitorSwitch ? data.visitorSwitch : data.visitorSwitch,
        hotScores:data.hotScores
      }
      this.$store.commit(SET_ROOM_ID, data.roomNo);
      this.$store.commit(SET_ROOM_INFO, roomInfo);
      this.$store.commit(UPDATE_USER_INFO, userInfo);
    },
    setStateRoomData(data){
      if(StringUtils.isEmpty(data.roomNo)){
        return false;
      }
     
      let roomInfo = {
        roomId: data.roomNo,
        roomName: data.title,
        logoCover: data.logoCover,
        bgImg: data.bgImg,
        // filterType:data.filterType,
        watchFilter:data.watchFilter,
         //filterType:register_filter,
        roomStatus:data.roomStatus ? data.roomStatus : data.status,
        roomType:data.roomType ? data.roomType : data.type,
        lineType:data.lineType ? data.lineType : data.lineType,
        startTime:data.startTime,
        visitorSwitch:data.visitorSwitch ? data.visitorSwitch : data.visitorSwitch,
        hotScores:data.hotScores
      }
      this.$store.commit(SET_ROOM_ID, data.roomNo);
      this.$store.commit(SET_ROOM_INFO, roomInfo);
    },
    setShowFilter(val,userId){
      this.$store.commit(STATE_SHOW_FILTER, val);
      StorageUtils.setSessionItem(USER_SHOW_FILTER,{userId:userId},val);
    },
    getShowFilter(userId){
      let userFiliet = this.showFilter
      if (!this.showFilter){
        userFiliet = StorageUtils.getSessionItem(USER_SHOW_FILTER,{userId:userId});
      }
      return userFiliet;

    },
    setUserLoacData(userId, data){
      let localItem = StorageUtils.getSessionItem(USER_DATA_KEY, { userId:userId });
      if (localItem){
        localItem.roomStatus = data.status;
        localItem.watchFilter = data.watchFilter;
        localItem.visitorSwitch = data.visitorSwitch;
        StorageUtils.setSessionItem(USER_DATA_KEY, { userId:userId }, localItem)
      }
    },
    routerPusherurl(query){
      this.$router.push({
        path: '/pusher',
        query:{
          userId:query.userId,
          roomId:query.roomId
        }
      })
    },
    routerPlayerurl(query){
      this.$router.push({
        path: '/watch',    //跳转的路由
        query:{
          userId:query.userId,
          roomId:query.roomId
        }
      })
    },
    routerRommurl(query){
      this.$router.push({
        path: '/admin',    //跳转的路由
        query:{
          userId:query.userId,
          roomId:query.roomId
        }
      })
    },
    routerCheck(query){
      function is_weixn(){ 
        var ua = navigator.userAgent.toLowerCase();  
        if(ua.match(/MicroMessenger/i)=="micromessenger") {  
            return true;  
        } else {  
            return false;  
        }  
    }  
    if( is_weixn() ) {
      this.$router.push({
        path: '/check',    //跳转的路由
        query:{
          userId:query.userId,
          roomId:query.roomId
        }
      })
    } else {
      this.$router.push({
        path: '/admin',    //跳转的路由
        query:{
          userId:query.userId,
          roomId:query.roomId
        }
      });  
    }
    },
    routerIndex(){
      // 跳转登录
      this.$router.push({
        path: '/index'
      })
    },
    routerLogin(){
      // 跳转登录
      this.$router.push({
        path: '/login'
      })
    }
  },
};
