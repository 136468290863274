import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import state from '@/stores/state';
import getters from '@/stores/getters';
import actions from '@/stores/actions';
import IMmeaages from '@/stores/message/IMmeaages';
import voteList from '@/stores/message/voteList';
import liveScene from '@/stores/message/liveScene';
import hotOnline from '@/stores/message/hotOnline';
import mutations from '@/stores/mutations';
import playBack from '@/stores/message/playBack';



Vue.use(Vuex);

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  plugins: [
    // veux持久化配置
    createPersistedState({
      storage: window.sessionStorage,
      key: 'live_IMmeaages',
      paths: ['IMmeaages' , 'voteList','hotOnline','playBack','liveScene']
    })
  ],
  modules: {
    IMmeaages,
    voteList,
    liveScene,
    hotOnline,
    playBack
  },
});
