import StorageUtils from '@/putils/StorageUtils';
import AesUtils from '@/putils/aes/crypto.aes';
import {CONFIGLIST} from "@/constants/localKeys"
// import { userLogin } from '@/api/login.js';


function getUserToken(lockey) {
  let c_token = StorageUtils.getSessionItem(CONFIGLIST.SESSION_WEB_TOKENINFO, lockey);
  let aestoken = StorageUtils.getSessionItem(CONFIGLIST.SESSION_WEB_AESTOKEN, lockey);
  if (c_token == '' || c_token == null || c_token == undefined) {

    if (aestoken != '' && aestoken != undefined) {
      c_token = AesUtils.Decrypt(aestoken);
    }
  }
  if (c_token == '') {
    userLogin({ token: c_token }).then(res => {
      console.log('request->tokenL::' + res);
      if (res.code == 200) {
        let token = res.token;
        saveUserToken(token);
      }
    });
  }
  return c_token;
}

function saveUserToken(token, lockey) {
  StorageUtils.setSessionItem(CONFIGLIST.SESSION_WEB_TOKENINFO, lockey, token);
}
function clearAuthority() {}

export { getUserToken, saveUserToken, clearAuthority };
